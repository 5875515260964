@charset "utf-8";
/* CSS Document */

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* ------------------------------------------------------- */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" "Mulish" !important;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span {
  margin: 0;
  padding: 0;
  color: #000;
}

td,
th {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #000;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}

table {
  width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.fltL {
  float: left;
}

.fltR {
  float: right !important;
}

.padding_none {
  padding: 0 !important;
}

.border_none {
  border: none !important;
}

.navbar-toggle {
  background-color: #fff !important;
}

.icon-bar {
  background-color: #000 !important;
}

.p_right0 {
  padding-right: 0px !important;
}

.p_left0 {
  padding-left: 0px !important;
}

.margin0 {
  margin: 0 !important;
}

input[type="date"].form-control {
  text-transform: uppercase !important;
}

select {
  cursor: pointer;
  outline: 0;
  -webkit-appearance: menulist !important;
}

/* ---------------------Sidebar---------------------------------- */
.SidenavArea {
  width: 265px;
  position: fixed;
  top: 0px;
  height: calc(100vh - 20px);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
}

.SidenavArea .SidenavHead {
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
}

.SidenavArea .SidenavHead .Avatar {
  width: 50px;
}

.SidenavArea .SidenavHead .Avatar a {
  display: block;
  border: 2px solid rgb(34, 12, 12);
  border-radius: 50%;
}

.SidenavArea .SidenavBody ul {
  height: calc(100vh - 183px);
  overflow: auto;
  margin: 0 -7px 0 0;
  padding: 0 5px 0 0;
}

.SidenavArea .SidenavBody ul::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.SidenavArea .SidenavBody ul::-webkit-scrollbar-track {
  background: rgb(237, 237, 237);
  border-radius: 20px;
}

.SidenavArea .SidenavBody ul::-webkit-scrollbar-thumb {
  background-color: rgba(230, 27, 27, 0.822);
  border-radius: 10px;
}

.SidenavArea .SidenavBody ul li {
  margin: 0 0px 10px 0;
  border-bottom: 1px solid #cfcdcd;
}

.SidenavArea .SidenavBody ul li:last-child {
  margin: 0;
}

.SidenavArea .SidenavBody ul li a {
  display: block;
  cursor: pointer;
  padding: 12px 10px 12px 50px;
  transition: 0.5s all ease-in-out;
  color: #160e0e;
  font-size: 13px;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 600;
  white-space: initial;
}

.SidenavArea .SidenavBody ul li a:hover {
  display: block;
  cursor: pointer;
  padding: 12px 10px 12px 50px;
  transition: 0.5s all ease-in-out;
  color: #fff;
  font-size: 13px;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 600;
  white-space: initial;
  background-color: rgba(252, 8, 8, 0.63);
}

.SidenavArea .SidenavBody ul li a span {
  font-size: 14px;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SidenavArea .SidenavBody .subIcon {
  font-size: 14px;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SidenavArea .SidenavBody ul li ol {
  position: relative !important;
  float: none;
  transform: inherit !important;
  border: none;
  padding: 0;
  margin: 0 0px 0 0px;
  border-radius: 5px;
}

.SidenavArea .SidenavBody ul li ol li {
  padding: 0;
  margin: 0;
}

.SidenavArea .SidenavBody ul li ol li:last-child {
  margin-bottom: 0;
}

.SidenavArea .SidenavBody ul li.active ol {
  display: block;
}

.SidenavArea .SidenavBody ul li a.active {
  color: #fff;
  /* background-color: rgba(230, 27, 27, 0.822); */
  padding: 12px 10px 12px 50px;
}

/* ------------is child-active---------------- */
.SidenavArea .SidenavBody ul li a.active {
  color: #fff;
  background-color: #ff8989;
  /* border: 2px solid rgba(211, 23, 23, 0.849); */
}

.SidenavArea .SidenavBody ul li.active a span {
  background: rgb(237, 237, 237);
  color: #000;
}

.SidenavArea .SidenavFooter {
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  bottom: 10px;
  text-align: center;
}

.SidenavArea .SidenavFooter a {
  width: 100%;
  background-color: #fd4f4fe5;
  padding: 13px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  /* Change display to inline-block */
}

.SidenavArea .SidenavFooter a:hover {
  width: 100%;
  color: #fff;
  background-color: #ff8989;
  /* border: 2px solid rgba(211, 23, 23, 0.849); */
  padding: 13px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
}

.SidenavArea .SidenavFooter a span.Icon {
  font-size: 14px;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 7px;
  width: 32px;
  height: 32px;
  background-color: #fd4f4fe5;
  text-align: center;
  border-radius: 50px;
  line-height: 33px;
  color: #fd4f4fe5;
}

/* ---------------------component-page-WrapperArea----------------------------------- */
.mainHead {
  margin-left: 265px;
  flex: 1 1 auto;
  width: calc(100% - 265px);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  z-index: 9;
}

.WrapperArea {
  margin-left: 265px;
  flex: 1 1 auto;
  width: calc(100% - 265px);
  display: flex;
  flex-direction: column;
  background-color: #e1dfdf;
  padding: 20px;
}

.WrapperBox {
  padding: 20px;
  background-color: #fff;
  min-height: calc(100vh - 20px);
  border-radius: 10px;
  margin-top: 59px;
}

.TitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.TitleBox h4.Title {
  margin: 0;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 22px;
  color: #242424;
}

.TitleBox h5 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.TitleBox h5 strong {
  font-weight: 800;
}

.TitleBox a.TitleLink {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  background: #20547d;
  display: inline-flex;
  align-items: center;
}

.TitleBox a.TitleLink i {
  margin-right: 5px;
}

.TitleBox a.TitleLink span {
  width: 20px;
  display: inline-block;
  height: 20px;
  background-color: red;
  text-align: center;
  font-size: 12px;
  border-radius: 20px;
  line-height: 20px;
  margin: 0 0 0 10px;
}

.TitleBox a.TitleLink:hover {
  background-color: #d8181d;
}

.FilterBox {
  margin: 0 0 15px 0;
}

.FilterArea.FilterBox {
  background-color: #fff;
  padding: 15px;
  /* border: 2px solid #e7dcdc; */
  border-radius: 5px;
}

.FilterArea.FilterBox .category_detail-image {
  height: 200px;
  width: 400px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.FilterArea.FilterBox .category_detail-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

/*--------------------pagination-------------------------------- */
.Pagination {
  margin: 30px 0 0 0;
}

.Pagination ul {
  text-align: right;
  flex-wrap: wrap;
}

.Pagination ul li {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
}

.Pagination ul li a {
  padding: 4px 4px 4px 4px;
  border: 1px solid #ddd;
  width: 33px;
  display: block;
  font-size: 15px;
  color: #656363;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border-radius: 18%;
}

.Pagination ul li a:hover {
  background-color: #ff8989;
  color: #fff;
}

.Pagination ul li.active a {
  border-color: #ff8989;
  background: #ff8989;
  color: #fff;
  font-weight: 500;
}

.Pagination ul li:first-child a,
.Pagination ul li:last-child a {
  padding: 4px 4px;
}

/*--------------------login-Area--------------------------------------- */

.LoginArea {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.LoginArea .LoginBox {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* background-color: rgba(247, 145, 145, 0.844); */
  background-color: #fff2f2;
  padding: 35px;
  width: 100%;
  max-width: 500px;
  border: 2px solid #ff8989;
  border-radius: 15px;
}

.LoginArea .LoginBox figure {
  width: 125px;
  margin: 0 auto 20px;
}

.LoginArea .LoginBox h5 {
  text-align: center;
  margin: -32px 0 20px;
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.LoginArea .LoginBox h6 {
  text-align: center;
  margin: 0 0 30px;
  font-size: 14px;
  color: #565656;
  font-weight: 500;
  line-height: 19px;
}

.LoginArea .LoginBox .form-group {
  position: relative;
  margin: 0 0 20px 0;
}

.LoginArea .LoginBox .form-group label {
  font-weight: 500;
  color: #222;
  font-size: 14px;
  display: block;
}

.LoginArea .LoginBox .form-group .form-control {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px 0 45px;
  font-size: 14px;
}

.LoginArea .LoginBox .form-group .Icon {
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  position: absolute;
  left: 1px;
  z-index: 100;
  top: 26px;
  color: #000;
}

.LoginArea .LoginBox .form-group .Icon1 {
  width: 37px;
  height: 38px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  z-index: 100;
  top: 26px;
  color: #000;
  cursor: pointer;
}

.login_btn {
  border: none;
  padding: 10px 40px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 500;
  display: block;
  position: relative;
  background-color: rgba(204, 15, 15, 0.8435749299719888);
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  width: 50%;
  border-radius: 10px;
  margin-top: 20px;
}

.form_btn a {
  border: none;
  padding: 10px 40px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 500;
  display: block;
  position: relative;
  background-color: rgba(204, 15, 15, 0.8435749299719888);
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  width: 50%;
  border-radius: 10px;
  margin-top: 20px;
}

.login_btn a {
  color: #fff;
  text-transform: capitalize;
  margin: 0 auto;
}

.login_head {
  /* text-align: center; */
  color: #d8181d;
  font-size: 50px;
  margin-bottom: 15px;
  /* padding: 100px 0px 10px 0px; */
  font-weight: 700;
  /* background-color: #28B17B; */
}

.LoginBox .form_btn {
  display: flex;
  justify-content: center;
}

.icon {
  position: absolute;
  z-index: 9;
  right: 26px;
  top: 34px;
  cursor: pointer;
}

.LoginArea .LoginBox .form-group .Icon {
  width: 34px;
  height: 34px;
  background-color: #d4d4d4;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  position: absolute;
  left: 3px;
  z-index: 100;
  top: 28px;
  color: #17222c;
}

.LoginArea .LoginBox .Checkboxs {
  display: flex;
  justify-content: flex-end;
}

.LoginArea .LoginBox .Checkboxs a {
  color: rgb(16, 90, 142);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0;
  text-decoration: underline;
}

.LoginArea .LoginBox .Checkboxs a:hover {
  color: #1e3e6a;
}

.form-group .error_icon {
  display: none;
}

/* --------------------------------------------------------------------- */

.FilterArea.FilterBox .form-group.custm_width .form-control {
  min-width: 300px;
  width: 300px;
}

.FilterArea.FilterBox .form-group.custm_width_md .form-control {
  min-width: 261px;
  width: 261px;
}

.FilterArea.FilterBox .form-group.custm_width_more .form-control {
  min-width: 320px;
  width: 320px;
}

.FilterBox .form-group {
  margin: 0 10px 0px 0;
  display: inline-block;
}

.FilterBox .form-group:last-child {
  margin: 0 0 0px 0;
}

.FilterBox .form-group label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.FilterBox .form-group .form-control {
  height: 40px;
  line-height: 25px;
  padding: 4px 4px;
  border: 2px solid #cbcaca;
  font-size: 13px;
  margin: 0 10px 0 0;
  box-shadow: none;
  color: #222;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.FilterBox.FilterArea {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.FilterBox.FilterArea .form-group {
  width: 100%;
  display: block;
}

.FilterBox.FilterArea .form-group.date_picker {
  max-width: 170px;
}

.FilterBox.FilterArea .form-group.select_width {
  max-width: 200px;
}

.FilterBox.FilterArea .form-group.select_sm_width {
  max-width: 170px;
}

.FilterBox.FilterArea .form-group.date_picker .react-datepicker__calendar-icon {
  left: auto;
  right: 0;
  top: 4px;
  font-size: 16px;
}

.FilterBox.FilterArea .form-group.refresh_button {
  width: auto;
  /* background-color: #c53737da; */
}

.FilterBox.FilterArea .form-group.search {
  max-width: 250px;
}

.FilterBox.FilterArea .form-group.search_md_size {
  max-width: 350px;
}

.FilterBox.FilterArea .form-group.search_lg_size {
  max-width: 380px;
  /* margin-bottom: 10px; */
}

.CommonForm .form-group {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}

.CommonForm .form-group .icon {
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  position: absolute;
  right: 3px;
  z-index: 100;
  top: 30px;
  color: #17222c;
}

.CommonForm .form-group:last-child {
  padding: 0;
  border: none;
}

.CommonForm .form-group label {
  color: #17222c;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 10px 0;
}

.CommonForm .form-group .form-control {
  height: auto;
  padding: 8px 10px 8px 15px;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
}

.CommonForm .form-group .ck.ck-editor {
  margin: 0 0 20px 0;
}

.CommonForm .form-group .ck.ck-label.ck-voice-label {
  display: none;
}

.CommonForm .form-group .ck.ck-content.ck-editor__editable {
  padding: 10px;
  min-height: 275px;
}

.CommonForm .form-group .Icon {
  position: absolute;
  right: 11px;
  top: 42px;
}

.ModalBox .modal-dialog .Category .form-group {
  margin: 0 0 35px;
  position: relative;
}

.ModalBox .modal-dialog .Category .form-group label {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
  font-size: 13px;
  background-color: #fff;
  margin: 0 0 -10px 0;
  padding: 6px 10px;
  position: absolute;
  top: -17px;
  left: 10px;
  border-radius: 5px;
  z-index: 1;
  font-family: "Roboto";
}

.ModalBox .modal-dialog .Category .form-group .form-control {
  box-shadow: none;
  border: 1px solid #c3c3c3;
  height: auto;
  font-size: 14px;
  color: #000;
  line-height: 22px;
  font-family: "Roboto";
  padding: 15px 15px 10px;
}

.ModalBox .modal-dialog .Category .form-group textarea.form-control {
  height: auto;
}

.custom_modal .modal-body,
.custom_modal .modal-footer {
  justify-content: center !important;
}

.custom_modal .modal-body .description {
  text-align: center;
  font-size: 15px;
}

.CommissionBox .form-group {
  display: inline-block;
  margin: 0 2px 0 !important;
}

.form-group.tabs {
  width: 400px;
}

/* ------------------Listing-td-tr--------------------- */
.content-table {
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: auto;
}

.content-table table {
  border-collapse: collapse;
}

.content-table thead tr {
  /* background: #ff9f9f; */
  text-align: left;
  font-weight: bold;
  border: 1px solid #212529;
}

/* ----------------order-view-Listing-td-tr--------------------- */

/* ********table thead and table row************ */
.content-table th,
.content-table td {
  padding: 10px 15px;
  text-wrap: nowrap;
  text-align: left;
}

.content-table th {
  font-weight: 600;
  font-size: 14px;
}

.content-table tbody tr {
  border-bottom: 1px solid #212529;
}

.content-table tbody tr td {
  font-size: 15px;
  border-bottom: 1px solid #212529;
  color: #000;
  text-align: left;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #e1dfdf;
}

/* .content-table tbody tr:last-of-type {
  border-bottom: 2px solid #212529;
} */

.content-table tbody tr {
  border-left: 1px solid #212529;
  border-right: 1px solid #212529;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #0c0c0a;
}

.content-table tbody tr td.user_profile_image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* .content-table::-webkit-scrollbar-thumb {
  background-color: #red;
  transition: 0.5s all ease-in-out;
  border-radius: 10px;
  display: block;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.content-table::-webkit-scrollbar {
  width: 4px;
  border-radius: 20px;
  height: 5px;
} */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #fd4f4fe5;
  /* background: linear-gradient(90deg,
      rgba(149, 103, 45, 1) 0%,
      rgba(245, 237, 156, 1) 35%,
      rgba(163, 121, 60, 1) 100%); */
  border-radius: 10px;
}

.btn_status {
  border: 1px solid red;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: red;
}

/* --------------------------------------------------------------- */
.form-control:focus,
.form-control.active {
  color: #212529;
  background-color: #fff;
  border-color: #d37676 !important;
  outline: 0;
  box-shadow: 0 0 0 1px #d37676 !important;
  transition: 0.1s all ease-in-out;
}

.disabled .form-control:focus {
  background-color: #e9ecef;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

select.form-control.is-invalid {
  background-position: right calc(0.8em + 0.1875rem) center;
}

/* ------------------------------image-show---------------------------------------- */
.imgSize {
  width: 105px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #0489ed;
  border-radius: 5px;
  margin: 10px;
}

.imgContainer {
  position: relative;
  display: inline-block;
}

.croseBtn {
  position: absolute;
  bottom: 70%;
  left: 75%;
  cursor: pointer;
  color: #0489ed;
  font-size: 21px;
}

.croseBtn:hover {
  background-color: #e4dfdf;
  border: 1px solid #e4dfdf;
  border-radius: 50%;
  color: #000;
  font-size: 21px;
}

.croseBtn1 {
  position: absolute;
  top: 27px;
  right: 15px;
  font-size: 40px !important;
  cursor: pointer;
  color: #0489ed;
}

.chooes {
  border: 1px solid black;
  margin-top: 11px;
  width: 115px;
  padding: 5px 5px 5px 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

/* ------------------------view-page------------------------------------- */
.OrderArea ul {
  padding: 10px;
  border: 1px solid #adc8dc;
  margin: 4px;
  border-radius: 5px;
}

.OrderArea ul li {
  font-size: 15px;
  list-style: none;
  border-bottom: none;
  color: black;
}

.OrderArea ul li span {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  max-width: 300px;
  display: inline-block;
  position: relative;
  padding: 0 15px 0 0;
  margin: 0 15px 0 0;
  padding: 10px 20px;
  text-transform: capitalize;
}

.OrderArea ol li p {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  max-width: 300px;
  display: inline-block;
  position: relative;
  padding: 0 15px 0 0;
  margin: 0 15px 0 0;
  padding: 10px 20px;
  text-transform: capitalize;
}

.viewEmpProfile {
  border: 1px solid black;
  border-radius: 50%;
  margin: 10px;
  height: 100px;
  width: 105px;
}

.Custm_heading {
  background-color: #adc8dc;
  width: 100%;
  padding: 5px 5px 5px 5px;
  border: 1px solid #9abed9;
  border-radius: 5px;
  color: #0f0d0d;
  text-align: center;
}

.removeBorder {
  border: none !important;
  margin-top: -20px !important;
}

.mlCustm {
  margin-left: 100px;
}

/* -------------------------loop-input-field-icons-"+ or -"-------------------------------- */
.add_user_form_field {
  position: relative;
}

.add_user_icon {
  position: absolute !important;
  top: 20px;
  right: 37px;
}

.add_btn_wrapper button {
  padding: 8px 50px;
}

/* -------------------------------------------------- */

/* Header///////////////////////////////////////////////////////////////////////////////////////// */

.header {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_notification {
  display: flex;
  align-items: center;
  position: relative;
}

/*----------------------password-reset-success----------------------------------- */
.link_color {
  color: white;
  text-decoration: none;
}

.link_color:hover {
  color: white;
}

.success_logo {
  width: 100px;
  display: block;
  margin: 0 auto 10px;
}

/* -----------------modal-css------------------------------- */
.image_size {
  width: 400px;
  height: 140px;
  width: "auto";
  border: 1px solid #ff8989;
  border-radius: 5px;
  object-fit: unset;
  cursor: pointer;
}

.btn_size {
  padding: 6px 35px;
  border-radius: 5px;
  background-color: #ff8989;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
}

.btn_size_transparent {
  padding: 6px 35px;
  border-radius: 5px;
  background-color: #d5bcbc;
  color: #000;
  border: none;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
}

.btn_size_opaque {
  padding: 6px 35px;
  border-radius: 5px;
  background-color: #fd4f4fe5;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
}

.btn_size:hover {
  background-color: #fd7886;
  border: 2px solid #dc3545;
}

.custom_button {
  padding: 6px 35px;
  border-radius: 5px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

/* ---------------------not-fond-page-404---------------------------------- */

.LoginArea .LoginBox .error_container .errorImg img {
  width: 400px;
  height: 100%;
  margin-top: 90px;
}

.LoginArea .LoginBox .error_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginBox .resend_email_btn button {
  font-size: 1rem !important;
  outline: none;
  border: none;
  background: none;
  margin-right: 0px;
  padding: 10px;
}

.LoginBox .resend_email_btn span {
  font-size: 1rem !important;
}

.LoginBox .resend_email_btn {
  margin: 10px 20px;
  text-align: center;
}

/* -------------------only-show-data-onpage-or-modal-------------------------------------- */
.cstm_span_style {
  /* background-color: lightgray; */
  background-color: #e9ecef;
  font-size: 20px;
  text-align: center;
  color: #000;
  font-weight: 500;
  border-radius: 10px;
  padding: 3px 3px;
  margin-bottom: 15px;
}

/* ------------------view-deatils-page-------------------------------------- */
.customer_details {
  border: 1px solid #9dc4b4;
  border-radius: 10px;
  padding: 20px;
}

.customer_details ul li {
  margin: 12px;
}

/* .customer_details ul p {
  margin-left: 28px;
} */

.bisStatus {
  border: 1px solid #fff3d8;
  padding: 8px 15px 8px 16px;
  border-radius: 15px;
  color: #fec53d;
  background-color: #fff3d8;
}

.bidStatus {
  border: 1px solid #e0d4fc;
  padding: 8px 15px 8px 16px;
  border-radius: 15px;
  color: #6226ef;
  background-color: #e0d4fc;
}

/* -------------react-datepicker-cross-icon-css------------- */
/* .react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: -1px;
  right: 7px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
} */

/* --------------seller-national-id-like-addhar-card-------------------------- */
.profileId {
  width: 400px;
  height: 260px;
  object-fit: fill;
  border: 2px solid #9dc4b4;
  border-radius: 7px;
}

.profileIdView {
  width: 150px;
  height: 100px;
  object-fit: fill;
  border: 2px solid #9dc4b4;
  border-radius: 7px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
  width: 350px;
  height: 210px;
  border: 2px solid #9dc4b4;
  border-radius: 10px;
  object-fit: fill;
}

.modal-img-size {
  width: 100%;
  height: 350px;
  border: 2px solid #9dc4b4;
  border-radius: 10px;
  object-fit: cover;
}

/* ---------------------------------------- */
.order_images_wrap {
  display: flex;
  flex-wrap: wrap;
}

.order_images_wrap .order_image_card {
  padding: 10px;
  width: calc(100% / 5);
  overflow: hidden;
}

.order_images_wrap .order_image_card .MuiAvatar-root {
  border: 0;
  border-radius: 10px;
  width: 100%;
  height: 161px;
  cursor: pointer;
}

/* ----------------------------------- */
.cuesor_none {
  cursor: default !important;
}

.color {
  color: #28b17b;
  font-size: 30px;
}

/* --------------order-view--------------------------- */
.view_card {
  border: 1px solid #e7dede;
  border-radius: 10px;
  box-shadow: 4px 4px 2px -1px #0000000d;
  background: #f9f9f9;
  position: relative;
}

.custm_p {
  padding: 6px 15px 5px 15px;
}

.border_line {
  border: 1px solid #e7dede;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.border_top {
  border-top: 1px solid #e7dede;
}

.SidenavArea .SidenavHead h2 {
  color: rgba(211, 23, 23, 0.849);
}

/* order listing */
.user_management_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: 980px; */
}

.user_management_heading span.user_management_excel {
  font-size: 35px;
  cursor: pointer;
  margin: 0;
}

.form-group.search_lg_size.userSearch {
  position: relative;
}

.userSearch span.user_search_icon {
  position: absolute;
  top: 4px;
  left: 6px;
  font-size: 20px;
}

.form-group.search_lg_size.userSearch input {
  padding: 0px 29px;
}

/* Target the Select component */
.select_sm_width select {
  width: 200px;
  padding: 8px;
  font-size: 15px;
  color: #333;
}

/* Target the options within the select */
.select_sm_width select option {
  background-color: #fff;
  color: #333;
  padding: 8px;
  font-size: 15px;
}

.FilterBox .group_filter_value {
  display: flex;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* 
.FilterBox .group_filter_value .date_picker {
  width: 156px;
} */

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #ff8989 !important;
  color: rgb(0, 0, 0);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #ff8989 !important;
  color: #fff;
}

.action_button {
  /* max-width: 110px; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action_button a {
  margin-right: 5px;
}

.action_button a:last-child {
  margin-right: 0;
}

.back_button {
  color: #ff8989;
}

.user_detail_heading {
  display: flex;
  align-items: center;
}

/* User Detail */

.small_container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user_detail_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 180px;
  /* border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.user_detail_container .user_image {
  height: 100px;
  width: 100px;
  margin-top: auto;
}

.user_detail_container .user_image figure {
  height: 100px;
  width: 100px;
  /* border: 2px solid red; */
  border-radius: 50%;
  overflow: hidden;
}

.user_detail_container .user_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user_detail_container ul.selected_user_detail {
  width: 600px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.user_detail_container ul.selected_user_detail li {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.user_detail_container ul.selected_user_detail li p:first-child {
  font-weight: 500;
  margin-right: 5px;
  max-width: 160px;
  width: 100%;
}

.block_user_button {
  align-self: flex-start;
}

.block_user_button button {
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ff8989;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.block_user_button button:hover {
  background-color: #d37676;
}

.user_personal_info {
  margin: 20px 0px;
}

.selected_user_personal_detail li {
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

ul.selected_user_personal_detail li p:first-child {
  font-weight: 500;
  margin-right: 10px;
  width: 150px;
}

.selected_user_personal_detail li.user_saving_container {
  justify-content: space-between;
  font-size: 17px;
}

.selected_user_personal_detail .user_saving_amount span:first-child {
  font-weight: 500;
  margin-right: 20px;
  width: 150px;
}

.listing {
  margin: 10px 0px;
  display: flex;
  /* justify-content: space-between; */
}

.listing p {
  font-size: 16px;
  padding: 4px 8px;
  margin-right: 10px;
  border-radius: 4px;
  /* background-color: #FF8989;
  color: #fff; */
}

.primary_button {
  color: #fff;
  background-color: #ff8989;
  border-radius: 4px;
}

/* Global styles */
.tab_table {
  margin-top: 20px;
}

/* Custom styles for the tab wrapper */
.custom-tabs {
  background-color: #ffcccc;
  border: 1px solid #ff0000;
  border-radius: 4px;
  padding: 10px;
}

/* Styles for the tabs */
.nav-tabs {
  border: none;
}

.nav-tabs .nav-item .nav-link {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #495057;
  width: 100%;
  min-width: 165px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link {
  font-weight: 500;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link:hover {
  background-color: #ffdddd;
} */

.nav-tabs .nav-item .nav-link.active {
  background-color: #ff8989;
  color: white;
  border: none;
  font-weight: bold;
}

/* Wrapper styles */
.WrapperBox .wishlist_activ {
  display: flex;
  align-items: center;
  /* border: 2px solid #fff3d8; */
  background-color: #fff2f2;
  width: 100%;
  max-width: 363px;
  border-radius: 10px;
  padding: 4px 8px;
}

.WrapperBox .wishlist_activ li {
  margin-right: 10px;
}

.WrapperBox .wishlist_activ li:last-child {
  margin-right: 0;
}

.eye_icon {
  color: #8a8c8e;
  font-size: 26px;
}

.tick_icon {
  color: #ff8989;
  font-size: 26px;
}

.listing_chip {
  font-size: 17px !important;
  padding: 2px 6px !important;
  margin-right: 10px;
}

.MuiFormControlLabel-root .MuiSwitch-thumb {
  color: #fff;
}

/* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track { */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,
.css-1nr2wod.Mui-checked+.MuiSwitch-track {
  background-color: red !important;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: red !important;
}

.css-1uf4bbi.Mui-checked+.MuiSwitch-track {
  background-color: red !important;
}

.company_logo {
  width: 70px;
  height: 100px;
}

.company_logo img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.user_saving_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.user_saving_container .MuiPaper-root {
  background-color: #f0f0f0;
  padding: 10px;
}

.user_saving_container .MuiPaper-root .user_saving_amount {
  height: 122px;
  width: 280px;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  align-items: center;
  /* margin-top: auto; */
  padding: auto;
  font-weight: 500;
}

.user_saving_container .MuiPaper-root .user_saving_amount span {
  margin-top: 18px;
}

.content-table tbody tr td .MuiAvatar-root {
  text-align: left;
  /* margin: 0px auto; */
}

/* image picker css */

.file-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  cursor: pointer;
}

.file-input.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.file-input.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.file-wrap .invalid-feed {
  color: #dc3545 !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

/* .file-input:hover ~ .file-input .choose {
  background: #848485;
} */

.file-input .choose {
  background: #f8f9fa;
  padding: 7px 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.file-input .file-name {
  padding: 7px 10px;
}

.button-label {
  display: inline-block;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #ff8989;
  color: #fff;
  border-radius: 4px;
}

.button-label:hover {
  background-color: #dc3545;
}

.music-player {
  max-width: 300px;
  margin: 10px auto;
}

.play-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.music-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem 0px !important;
  color: #212529 !important;
}

.music-wrapper p {
  color: #212529 !important;
  font-size: 14px !important;
}

.music-wrapper p::after {
  content: " *";
  color: red;
  /* Or any other color you prefer */
}

/* .text-wrapper label {
  color: #212529 !important;
  font-size: 14px !important;
} */

.text-wrapper label::after {
  content: " *";
  color: red;
  /* Or any other color you prefer */
}

.banner_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner_button_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff2f4;
  width: auto;
  /* max-width: 50%; */
  border-radius: 10px;
  padding: 6px 8px;
}

.banner_button_wrapper ._button.banner_active {
  background-color: #ff8989;
  color: white;
  border: none;
  font-weight: bold;
}

.banner_button_wrapper ._button {
  background-color: transparent;
  border: 1px solid #ff8989;
  border-radius: 4px;
  color: #ff8989;
  height: 40px;
  min-width: 165px;
  margin-right: 10px;
}

figure.not-found-image {
  height: 40px;
  width: 40px;
  margin: 2px auto;
}

figure.not-found-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.css-3r27n0-MuiButtonBase-root-MuiButton-root {
  background-color: #ff8989 !important;
  border-color: #d8181d !important;
}

.music_text {
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.music_text:hover {
  text-decoration: none;
}

.music_text:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 30%;
  width: 100%;
  height: 2px;
  background-color: #ff8989;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.music_text:hover:after {
  transform: scaleX(1);
}

.video_thumbail .react-thumbnail-generator img {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  border: 1px solid #ff8989;
  object-fit: cover;
}

.thumbnail-container {
  position: relative;
}

/* .meditation_video_thumbail {
  position: relative;
} */

.video_playbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff8989;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-icon {
  color: white;
  font-size: 36px;
}

.video-container video {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  border: 1px solid #ff8989;
  object-fit: cover;
}

.pagination-wrapper {
  display: flex;
  justify-content: end;
}

.pagination-wrapper .limit-page-wrapper {
  display: flex;
}

.consultant_button_wrapper {
  margin-bottom: 10px;
}

.consultant_performance {
  font-size: 25px;
  cursor: pointer;
}

.music_icon svg {
  font-size: 25px;
  cursor: pointer;
}

.video_icon svg {
  font-size: 25px;
  cursor: pointer;
}

.model_custom_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model_custom_footer p {
  font-size: 12px;
  margin-right: 8px;
}

.resend_email_btn button {
  font-weight: 500;
}

.css-1c7pagv {
  background-color: #ff8989 !important;
  border-color: #d8181d !important;
}

.consultant-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 30%;
}

.consultant-wrapper .block_user_button {
  margin: auto 0px;
}

.consultant-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.form-group {
  margin-right: 10px;
  /* Adjust as needed */
  margin-bottom: 10px;
  /* Adjust as needed */
}

.userSearch {
  flex: 1;
  /* Takes up remaining space */
  display: flex;
  align-items: center;
}

.group_filter_value {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.date_picker,
.select_sm_width {
  flex: 1;
  /* Equal width for these elements */
}

.refresh_button {
  margin-left: auto;
  /* Push to the right */
}

.consultant-header .search_lg_size {
  margin-bottom: 10px;
}

select.form-control.add-consultant {
  width: 100%;
}

.css-t3ipsp-control {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #d37676 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 1px #d37676 !important;
  transition: 0.1s all ease-in-out !important;
}

.row.consultant-container {
  margin-top: 10px;
  background-color: #fff2f4;
  border-radius: 8px;
  padding: 0px 13px;
}

.cstM-tele_input .react-tel-input .form-control {
  padding: 0.375rem 0.75rem 0.377rem 60px;
  width: 100%;
}

/* type search */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-color: var(--color);
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 34px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ff8989;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/* user image logo */
.row .user_icon figure img {
  /* max-width: 140px; */
  /* width: 100%; */
  /* border-radius: 50%; */
  /* overflow: hidden; */
  /* max-height: 140px !important; */
  height: 100%;
  object-fit: fill;
  width: 100%;
}

/* .row .user_icon figure img {
  width: 100%;
} */

.row .user_image figure {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.row.cstm_row_overwrite .user_detail_container {
  height: unset;
}

/* .row.cstm_row_overwrite .user_detail_container .selected_user_detail li p{
  width: unset;
} */
.listing .css-gavykb-MuiChip-root {
  border-radius: 0;
}

.image-preview img {
  object-fit: fill;
}

textarea.meditation_description {
  width: 100%;
  outline: none;
  padding: 10px;
  height: 110px;
  resize: none;
  font-size: 14px;
}

textarea.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375r);
}

.approve_image {
  position: relative;
}

.approve_image figure {
  height: 195px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}

.approve_image figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.approve_image .MuiFormControl-root {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
}

.user_detail_cstm_container {
  display: flex;
  height: 180px;
}

.row.cstm_row_align {
  align-items: center;
}

.row.cstm_row_align .user_icon figure {
  height: 140px;
  margin: 0 auto;
}

.row.cstm_row_align .user_icon figure img {
  height: 100%;
  object-fit: cover;
}

.csv-image-wrapper figure {
  width: 45px;
  height: 45px;
}

.csv-image-wrapper figure img {
  height: 100%;
  width: 100%;
}

ol.custom_toast li {
  height: 50px;
  padding: 20px;
}

td figure.consultant-performance-icon {
  margin: auto auto;
  cursor: pointer;
}

.text-center {
  text-align: center;
  margin-top: 20px;
  /* Adjust the value as needed */
}

.FilterBox.FilterArea .form-group.date_picker .react-datepicker__calendar-icon {
  left: auto;
  right: 10px;
  top: 4px !important;
  font-size: 16px !important;
}

.react-datepicker__close-icon::after {
  background-color: #ff8989 !important;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 15px;
}

.react-datepicker__close-icon {
  right: 30px !important;
}

p.cstm_status {
  height: 54px;
  margin-left: 130px;
  width: 165px;
  color: #fff;
  background-color: darkgray;
  text-align: center;
  border-radius: 5px;
  font-size: large;
  padding: 11px;
  margin-bottom: 41px;
}

.block_user_button .disabledBtn {
  background-color: darkgrey;
  color: #fff;
}

.block_user_button .disabledBtn:hover {
  background-color: darkgrey;
  color: #fff;
}

.status_tab {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #41b06e38;
  color: #41b06e;
  display: flex;
  align-items: center;
  line-height: 1;
}

.status_tab.pending {
  background-color: #ffaf4533;
  color: #ffaf45;
}

.checkbox_Wrap {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.checkbox_Wrap label {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.checkbox_Wrap label input {
  margin-left: 15px;
}

.cstm_consultant_profile_image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 150px; */
  /* max-height: 150px; */
  /* width: 150px;
height: 150px; */
}

.MuiAvatar-root MuiAvatar-circular css-1wlk0hk-MuiAvatar-root .img {
  width: 200px;
  height: 200px;
}

.hidden-input {
  display: none;
}

.text-capitalize {
  text-transform: capitalize;
}

/* .css-tj5bde-Svg{
  display: none !important;
} */

.hide_multiSelect_arrow .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.date_picker_wrap .react-datepicker-wrapper {}

.date_picker_wrap .react-datepicker-wrapper input.form-control.undefined {
  height: auto;
  border: transparent;
  padding-left: 30px;
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon {
  display: flex;
  align-items: center;
}

/* .react-datepicker__input-container.react-datepicker__view-calendar-icon svg {
} */

.btn_size_opaque:hover {
  background-color: #b25656;
}


/*--------------------------- Discount Management Styling ------------------ */

.card-with-shadow {
  width: 18rem;
  transition: box-shadow 0.3s ease;
  /* Adding transition for smooth effect */
}

.card-with-shadow:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add your desired box shadow here */
}

.card-actions {
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: to make the background semi-transparent */
  padding: 5px;
  border-radius: 5px;
}

.fixed-image {
  width: 100%;
  /* Adjust as needed, e.g., 100% or a specific value */
  height: 200px;
  /* Adjust as needed */
  object-fit: cover;
  /* Ensures the image covers the area without distorting */
}

.card-actions {
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: semi-transparent background */
  padding: 5px;
  border-radius: 5px;
}


/* Jodit Editor Style  */

#myjoditEditor .jodit-react-container ol li {
  list-style: inside;
  list-style-type: number;
}

/* #myjoditEditor .jodit-react-container ul li {
  list-style: inside;
} */

span.jodit-toolbar-button.jodit-toolbar-button_variant_initial.jodit-toolbar-button_image.jodit-ui-group__image.jodit-toolbar-button_size_medium {
  display: none;
}

span.jodit-toolbar-button.jodit-toolbar-button_variant_initial.jodit-toolbar-button_file.jodit-ui-group__file.jodit-toolbar-button_size_medium {
  display: none;
}

span.jodit-toolbar-button.jodit-toolbar-button_variant_initial.jodit-toolbar-button_video.jodit-ui-group__video.jodit-toolbar-button_size_medium {
  display: none;
}



/* -----------------------------Chat box------------------------------------- */

/*
.chat-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-header {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.chat-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.incoming-message {
  justify-content: flex-start;
}

.outgoing-message {
  justify-content: flex-end;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.outgoing-message .message-content {
  background-color: #e0ffe0; 
  text-align: right;
}

.input-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.textarea-container {
  position: relative;
  flex: 1;
}

textarea {
  width: 100%;
  padding: 10px 40px 10px 10px; 
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: none;
}

.attach-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #007bff;
}

.send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}
*/



.chat-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chat-box .cstm_chat_scrolling{
  min-height: 10vh;
  max-height: 55vh;
  overflow-y: auto;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.incoming-message {
  justify-content: flex-start;
}

.outgoing-message {
  justify-content: flex-end;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.message-content,
.media-content {
  max-width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.media-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.video-content,
.audio-content,
.image-content {
  max-width: 100%;
  border-radius: 10px;
}

.outgoing-message .message-content {
  background-color: #e0ffe0;
  text-align: right;
}

.input-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.textarea-container {
  position: relative;
  flex: 1;
}

textarea {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: none;
}

.attach-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #007bff;
}

.send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}


/*------------------------------------------------- Dashboard---------------------------- */


.dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stats {
  display: flex;
  gap: 20px;
}

.stat-item {
  background: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
}

.stat-item span {
  display: block;
  font-size: 1.5em;
  margin-top: 5px;
}

.controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.settlement-button {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.revenue-details {
  margin-bottom: 20px;
}

.revenue-details h3 {
  margin-bottom: 10px;
}

.chart {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.membership-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.membership-item {
  background: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  flex: 1 1 200px;
}

.membership-item span {
  display: block;
  font-size: 1.2em;
  margin-top: 5px;
}



/* Consultant Check Performance */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  width: 100%;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.title {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.value {
  font-size: 24px;
  font-weight: bold;
  color: #ff8989;
}



.cstm_icon_preview {
  font-size: 40px;
  text-align: center;
}

.dropbtns {
  background-color: unset;
  color: #000;
  padding: 0px 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdowns {
  position: relative;
  display: inline-block;
}

.dropdown-contents {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-contents a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-contents a:hover {
  background-color: #f1f1f1
}

.dropdowns:hover .dropdown-contents {
  display: block;
}

.dropdowns:hover .dropbtns {
  background-color: #fff;
}



/* This is for scrollbar in suppport management */
