.container {
    max-width: 800px;
    margin: auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .my-4 {
    margin: 2rem 0;
  }
  
  .mb-3 {
    margin-bottom: 1rem;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .form-check {
    margin-bottom: 0.5rem;
  }
  
  .me-4 {
    margin-right: 1.5rem;
  }
  
  .btn-primary {
    background-color: #000; /* Change the button color as per your design */
    border-color: #000; /* Change the button border color as per your design */
  }
  
  .btn-primary:hover {
    background-color: #444; /* Change the hover button color as per your design */
    border-color: #444; /* Change the hover button border color as per your design */
  }